<script setup lang="ts">
// use UiBadge instead
const props = withDefaults(
  defineProps<{
    color?: keyof typeof colors
    size?: keyof typeof sizes
  }>(),
  {
    color: 'blue',
    size: 'md',
  },
)

const colors = {
  'blue': 'text-forgd-primary-300',
  'neutral': 'text-forgd-neutral-700 bg-forgd-neutral-600/30 border-forgd-neutral-600/60',
  'gray': 'text-forgd-gray-600 bg-forgd-gray-600/10 border-forgd-gray-600/30',
  'yellow': 'text-forgd-primary-900 bg-forgd-bgd-400',
  'green': 'text-forgd-green-600 bg-forgd-green-600/10 border-forgd-green-600/30',
  'red': 'text-forgd-red-600 bg-forgd-red-600/10 border-forgd-red-600/30',
  'enabled': 'bg-forgd-bgd-300 text-forgd-primary-300',
  'enabled-gray': 'bg-forgd-bgd-300 text-forgd-primary-600',
  'disabled': 'bg-forgd-bgd-300 text-forgd-gray-450',
  'warning':
    'bg-forgd-yellow-100 text-forgd-yellow-700 border border-forgd-yellow-200',
}

const sizes = {
  sm: 'px-2 py-0.5 space-x-0.5',
  md: 'px-2 py-1 space-x-1',
  lg: 'px-4 py-2 space-x-2',
}
</script>

<template>
  <div
    data-ui="UiPill"
    class="font-mono font-medium text-sm tracking-tight flex items-center w-fit rounded-full border"
    :class="[colors[props.color], sizes[props.size]]"
  >
    <slot />
  </div>
</template>
